<!-- FoldingEvaluateComponent.vue -->
<template>
  <div class="flex items-center gap-8">
    <h3 class="flex-1 text-xl leading-lg break-all font-bold text-on-surface-elevation-2">
      {{ evaluateTitle }}
    </h3>
    <div class="shrink-0 flex items-center gap-8">
      <span
        v-if="dateValue && dateValue.length > 0"
        class="text-md leading-md text-on-surface-elevation-1 font-regular"
      >
        {{ dateValue }}
      </span>
      <s-button v-if="modifyUse" variant="outline" size="sm" @click="$emit('onModify')">
        {{ modifyUseTitle }}
      </s-button>
      <button type="button" class="h-24" @click="onOpen">
        <s-icon
          size="4xl"
          icon="ic-v2-control-arrow-down-line"
          class="text-on-surface-elevation-1 flex"
          :class="{ 'rotate-180': isOpenDetail }"
        />
      </button>
    </div>
  </div>
  <div v-if="isOpenDetail" class="pt-8 flex flex-col gap-16">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { defineModel } from 'vue';

defineProps({
  // eslint-disable-next-line vue/require-default-prop
  evaluateTitle: String,
  // eslint-disable-next-line vue/require-default-prop
  dateValue: String,
  // eslint-disable-next-line vue/require-default-prop
  modifyUseTitle: String,
  modifyUse: Boolean
});

const emits = defineEmits<{
  onFold: [v: boolean];
  onModify: [];
}>();

const isOpenDetail = defineModel<boolean>('isOpenDetail');
const onOpen = () => {
  isOpenDetail.value = !isOpenDetail.value;
  emits('onFold', !isOpenDetail.value);
};
</script>
